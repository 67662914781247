import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import imageOne from "../images/mgd/P1.jpeg"
import imageTwo from "../images/mgd/P2.webp"
import imageThree from "../images/mgd/P3.jpeg"
import imageFour from "../images/mgd/P4.webp"
import imageFive from "../images/mgd/P5.jpeg"
import imageSix from "../images/mgd/P6.jpeg"
import imageSeven from "../images/mgd/P7.jpg"
import imageEight from "../images/mgd/P8.jpeg"
import imageNine from "../images/mgd/P9.jpeg"
import imageTen from "../images/mgd/P10.webp"

const MachineGunDays = () => (
  <Layout>
    <SEO title="Firearms Courses Brazos County | Savage Outdoor & Defense" />
    <div className="">
      <div className="bg-gray-50 sm:py-16 sm:px-6 ">
        <div className="p-8 bg-gray-800">
          <div className="max-w-full mx-auto">
            <div className="flex items-center content-center justify-center text-center">
              <div className="text-center">
                <h2 className="text-2xl font-bold leading-7 text-white sm:text-3xl sm:leading-9 sm:truncate">
                  Machine Gun Days{" "}
                </h2>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div>
          <div className="max-w-3xl p-4 m-4 mx-auto bg-white rounded shadow md:max-w-4xl sm:max-w-full xs:max-w-full xs:mx-4 sm:mx-4 md:mx-4">
            <h1 className="my-3 text-3xl font-bold text-center">
              MACHINE GUN MADNESS
            </h1>
            <p className="text-center">
              <strong>Saturday, March 4th @ 8AM</strong>
            </p>
            <p className="my-3 text-center">
              Join us on Saturday, March 4th for our second annual Machine Gun
              Madness Day! We’ll be starting at 8AM and firing on all cylinders
              until supplies run out or the sun goes down. We will have
              full-auto MK18s, AK47s, HK420s, TAVOR SARs, and Scorpion EVOs
              available. Pick a package and get ready to be blown away!
            </p>

            <div className="flex flex-row flex-initial my-6 xs:flex-wrap">
              <img className="w-4/12 max-w-full xs:w-full" src={imageOne} />
              <p className="pl-3 xs:pl-0">
                <strong>Package 1: Yes SAR – Tavor Sar - $100</strong>
                <br />
                <span>
                  No BULL-pup that is! This Bullpup style rifle chambered in the
                  ever popular 5.56 caliber cartridge is sure to get your blood
                  pumping and the lead flying!
                </span>
              </p>
            </div>
            <div className="flex flex-row flex-initial my-6 xs:flex-wrap ">
              <img className="w-4/12 max-w-full xs:w-full" src={imageTwo} />
              <p className="pl-3 xs:pl-0">
                <strong>Package 2: JK? No, MK! - MK18 - $100</strong>
                <br />
                <span>
                  Do you love AR-15s? Have you ever seen one with the forbidden
                  3rd option? Well now you have! Come on out and let us show you
                  just how nice 3 options can be.
                </span>
              </p>
            </div>
            <div className="flex flex-row flex-initial my-6 xs:flex-wrap ">
              <img className="w-4/12 max-w-full xs:w-full" src={imageThree} />
              <p className="pl-3 xs:pl-0">
                <strong>Package 3: Don’t Be Russian! - AK47 - $100</strong>
                <br />
                <span>
                  Are you in a hurry or are you just Russian out to the range?
                  Either way if you’re looking for a big boom in a little
                  package then this is the one for you!
                </span>
              </p>
            </div>
            <div className="flex flex-row flex-initial my-6 xs:flex-wrap ">
              <img className="w-4/12 max-w-full xs:w-full" src={imageFour} />
              <p className="pl-3 xs:pl-0">
                <strong>
                  Package 4: Float Like A Butterfly, Sting Like A…Scorpion? –
                  Scorpion EVO - $75
                </strong>
                <br />
                <span>
                  I don’t think we got the saying right, but we definitely got
                  the firearm right! With this being our only handgun caliber on
                  the list we had to make sure to get one with a little sting!
                </span>
              </p>
            </div>
            <div className="flex flex-row flex-initial my-6 xs:flex-wrap ">
              <img className="w-4/12 max-w-full xs:w-full" src={imageFive} />
              <p className="pl-3 xs:pl-0">
                <strong>
                  Package 5: I don’t Know About You, But I’m Feeling 22 - HK420
                  - $65
                </strong>
                <br />
                <span>
                  This may be our cheapest option, but that doesn’t make it any
                  less enjoyable! Wanna full send on a budget? Want no recoil
                  for maximum control? Maybe you just want the joy of plinking.
                  No matter the reason, you can’t go wrong with our HK420 in
                  22LR.
                </span>
              </p>
            </div>
            <div className="flex flex-row flex-initial my-6 xs:flex-wrap ">
              <img className="w-4/12 max-w-full xs:w-full" src={imageSix} />
              <p className="pl-3 xs:pl-0">
                <strong>
                  Package 6: Budget Bois – Scorpion EVO & HK420 $125{" "}
                </strong>
                <br />
                <span>
                  Do you want the most bang for your buck? The most rounds for
                  your dollars? Then we have the package for you. The Budget
                  Bois get you the most rounds for the least amount of money. So
                  cash in, and send it.
                </span>
              </p>
            </div>
            <div className="flex flex-row flex-initial my-6 xs:flex-wrap ">
              <img className="w-4/12 max-w-full xs:w-full" src={imageSeven} />
              <p className="pl-3 xs:pl-0">
                <strong>
                  Package 7: David & Goliath – AK47 & HK420 - $150{" "}
                </strong>
                <br />
                <span>
                  This package combines our largest round and our smallest round
                  to give you the BOOM and the ZOOM! With this package you can
                  fill your need for speed and your need for power!
                </span>
              </p>
            </div>
            <div className="flex flex-row flex-initial my-6 xs:flex-wrap ">
              <img className="w-4/12 max-w-full xs:w-full" src={imageEight} />
              <p className="pl-3 xs:pl-0">
                <strong>
                  Package 8: The Trifecta – MK18, Tavor SAR, & AK47 - $275{" "}
                </strong>
                <br />
                <span>
                  Can’t decide which of the big boys is best for you? Maybe
                  you’ve dreamed of shooting some of the world’s most iconic
                  weapons. No matter what made you pick this package you are
                  bound to be blown away!
                </span>
              </p>
            </div>
            <div className="flex flex-row flex-initial my-6 xs:flex-wrap ">
              <img className="w-4/12 max-w-full xs:w-full" src={imageNine} />
              <p className="pl-3 xs:pl-0">
                <strong>
                  Package 9: Gotta Have ‘Em All! Tavor SAR, MK18, HK420, AK47, &
                  Scorpion Evo - $375{" "}
                </strong>
                <br />
                <span>
                  Can’t decide which to shoot? Maybe you just really love them
                  all, maybe you just wanna capitalize on the BEST deal you’ll
                  find. If you have to have them all then this is the package
                  for you!
                </span>
              </p>
            </div>
            <div className="flex flex-row flex-initial my-6 xs:flex-wrap ">
              <img className="w-4/12 max-w-full xs:w-full" src={imageTen} />
              <p className="pl-3 xs:pl-0">
                <strong>
                  Package 10: Dealer’s Choice! Pick Any 2 – Price Dependent{" "}
                </strong>
                <br />
                <span>
                  Think our packages suck? Hate all of our options? Maybe you’re
                  choosing this one just to be difficult. Honestly, those are
                  all fair reasons! If YOU want to create the best experience
                  for YOU then look no further. Pick any 2 of our 5 machine gun
                  options and get them BOTH for a discounted rate of $15 off
                  their combined price!
                </span>
              </p>
            </div>
            <div className="my-6">
              <p className="font-bold text-center">
                Each package comes with TWO 25 round magazines PER firearm.
                Additional magazines can be purchased.
              </p>
              <p className="font-bold text-center">
                $25 Deposit/Participant required upon registration. WE RESERVE
              </p>
              <p className="font-bold text-center underline uppercase">
                WE RESERVE THE RIGHT TO REMOVE YOU FROM THE FIRING LINE FOR ANY
                REASON AND WITHOUT WARNING{" "}
              </p>
              <p className="font-bold text-center">
                Refunds will NOT be given for violations of safety rules
              </p>
              <p className="text-center">
                Event put on in partnership with the Small Arms Humane Society.
              </p>
            </div>
            <iframe
              src="https://docs.google.com/forms/d/e/1FAIpQLSci1jkThweZalYTVC3QBBesod31mJIebLG6W2T7pHLToJViKg/viewform?usp=sf_link"
              height="1755"
              frameborder="0"
              marginheight="0"
              marginwidth="0"
              className="w-full max-w-full"
            >
              Loading…
            </iframe>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default MachineGunDays
